@media screen and (max-height: 864px) {
    .test {

        height: 100vh;
        width: 0px;
    }
}




.aboutUsBg{
    background-image:url("/public/images/home-bg.jpeg");
}
